import { useQuery } from '@tanstack/react-query';

import axiosFetch from '../../../../../utils/axiosConfig/axiosFetch';
import { FixtureTicketAttributes } from '../../../../../types/ticket.types';
import useClientStore from '../../../../clientStore/useClientStore';

type TicketQueryParams = {
  enabledRequest: boolean;
  fixtureId?: number;
};
const getFixtureTicketByUser = async (
  params: TicketQueryParams,
): Promise<FixtureTicketAttributes> => {
  const { fixtureId } = params;
  const response: FixtureTicketAttributes = await axiosFetch(
    `/ticket/fixture/${fixtureId}/type`,
  );

  return response;
};

export default function useGetFixtureTicketByUser(params: TicketQueryParams) {
  const { loggedUser } = useClientStore();

  return useQuery<FixtureTicketAttributes>({
    queryKey: ['ticket-fixture-by-user', loggedUser?.email, params?.fixtureId],
    queryFn:
      params.enabledRequest && !!params.fixtureId && !!loggedUser?.email
        ? () => getFixtureTicketByUser(params)
        : undefined,
  });
}
