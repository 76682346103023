import React, { useEffect, useRef, useState } from 'react';
import styles from './fixtureSection.module.css';
import { Column } from '../../../../../utils/layout';
import { FixtureAttributes } from '../../../../../types/fixture.types';
import { TournamentWithFixtureName } from '../../../../../types/tournament.types';
import { validateStartDate } from '../../../../../utils/hooks/handleDate';
import isValidateNumber from './components/utils/isValidateNumber';
import {
  useGetRound,
  useGetSavePredictionSecret,
} from '../../../../serverStore/queries';
import useClientStore from '../../../../clientStore/useClientStore';
import { RoundAttributes } from '../../../../../types/round.types';
import filterNullPredictions from '../../../../../utils/hooks/filterNullPredictions';
import useSavePrediction, {
  DataSavePredictions,
  ParamsSavePredictions,
} from '../../../../serverStore/mutations/prediction/useSavePrediction';
import { decryptData, fetchPredictions, isEqual } from '../../../../../utils';
import AuthModal, { AuthType } from '../../../../modals/auth/AuthModal';
import { Button, CustomTooltip } from '../../../../commons';
import { useSearchUserPrediction } from '../../../../serverStore/mutations';
import GoldSwitch from './components/goldSwitch/GoldSwitch';
import useGetFixtureTicketByUser from '../../../../serverStore/queries/ticket/fixture/useGetFixtureTicketByUser';
import defaultStadium from '../../../../../assets/stadium2.png';
import Ticket from '../../../../commons/ticket/Ticket';
import useChangeGoldenFixture from '../../../../serverStore/mutations/fixture/useChangeGoldenFixture';
import mobileStadium from '../../../../../assets/stadium1App.jpg';

type TFixtureSection = {
  fixture?: FixtureAttributes;
  isFixtureFinishedOpen: boolean;
  tournament?: TournamentWithFixtureName;
};

export type TOnChangePredictionProps = {
  matchId: number;
  team: 'local' | 'visitor';
  increase: boolean;
};

export type SimplePrediction = {
  matchId: number;
  predictionLocalTeam?: number;
  predictionVisitingTeam?: number;
};

export type PredictionsStorage = {
  predictions: SimplePrediction[];
  roundId: number;
};

const getInitialPredictions = (
  savePredictionsStorage?: PredictionsStorage,
  round?: RoundAttributes,
  fromStorage?: boolean,
) => {
  if (
    savePredictionsStorage?.predictions &&
    savePredictionsStorage?.roundId === round?.id
  ) {
    const newPred = round?.matches?.map((m) => {
      const pred = savePredictionsStorage.predictions?.find(
        (p) => p.matchId === m.id,
      );
      if (
        (pred && !fromStorage) ||
        (fromStorage &&
          pred &&
          validateStartDate(m.startDate) &&
          m.status !== 'finished' &&
          m.status !== 'inProgress')
      ) {
        return {
          matchId: m.id,
          predictionLocalTeam: pred.predictionLocalTeam,
          predictionVisitingTeam: pred.predictionVisitingTeam,
        };
      } else {
        return {
          matchId: m.id,
          predictionLocalTeam: undefined,
          predictionVisitingTeam: undefined,
        };
      }
    });
    return newPred || savePredictionsStorage.predictions;
  }
  if (round?.matches?.length) {
    return round.matches.map((m) => {
      return {
        matchId: m.id,
        predictionLocalTeam: undefined,
        predictionVisitingTeam: undefined,
      };
    });
  }
  return [];
};

const saveStoragePredictions = (p: SimplePrediction[], roundId: number) => {
  const savePredictions: PredictionsStorage = {
    predictions: filterNullPredictions(p),
    roundId,
  };
  localStorage.setItem('savePredictions', JSON.stringify(savePredictions));
};

const FixtureSection: React.FC<TFixtureSection> = ({
  fixture,
  tournament,
  isFixtureFinishedOpen,
}) => {
  const {
    fixtureSelected: { fixtureId, roundId, tournamentId },
    loggedUser,
    dispatch,
  } = useClientStore();
  const [isGolden, setIsGolden] = useState(false);

  const [authModal, setAuthModal] = useState<{
    open?: boolean;
    authType?: AuthType;
  }>({});

  const {
    data: fixtureTicket,
    refetch: refetchFixtureTicket,
    isLoading: isLoadingTicket,
  } = useGetFixtureTicketByUser({
    fixtureId,
    enabledRequest: !!fixtureId && !!loggedUser?.id,
  });

  const [showGoldenOption, setShowGoldenOption] = useState<boolean>(false);

  const {
    data: round,
    refetch: refetchRound,
    isLoading: isLoadingRound,
  } = useGetRound({
    fixtureId: fixtureId,
    roundId,
  });

  const isLoading = isLoadingRound || isLoadingTicket;

  const { data: secretSavePredictions } = useGetSavePredictionSecret();
  const { mutate: savePredictions, isPending: isPendingSave } =
    useSavePrediction({
      fixtureId,
      tournamentId,
    });
  const { mutate: changeGolden, isPending: isPendingGolden } =
    useChangeGoldenFixture(fixtureId);
  const {
    data: responsePredictions,
    mutate: searchMutate,

    reset,
  } = useSearchUserPrediction();

  const [predictions, setPredictions] = useState<SimplePrediction[]>([]);
  const [initalStatePrediction, setInitalStatePrediction] = useState<
    SimplePrediction[]
  >([]);

  const isChangePredictions = !isEqual(predictions, initalStatePrediction);
  const imageTournament = tournament?.image;
  const mobileImageTournament = tournament?.mobileImage;

  const isRoundFinished = !validateStartDate(round?.finishDate);

  /*   const showGoldenOption =
    !!loggedUser &&
    (isFixtureDateValidate ||
      (!isFixtureDateValidate && fixtureTicket?.ticketType === 'golden')); */

  const onChangeRoundId = (roundId?: number) => {
    dispatch({
      type: 'SET_ROUND_ID',
      payload: roundId,
    });
  };

  const onChangePrediction = ({
    increase,
    matchId,
    team,
  }: TOnChangePredictionProps) => {
    const newPredictions = predictions.map((p) => {
      if (p.matchId === matchId) {
        let newPredLocal =
          p.predictionLocalTeam != null ? p.predictionLocalTeam : 0;

        let newPredVisit: number =
          p.predictionVisitingTeam != null ? p.predictionVisitingTeam : 0;
        if (team === 'local') {
          if (increase) {
            newPredLocal = newPredLocal + 1;
          } else {
            newPredLocal = newPredLocal - 1;
          }
        }
        if (team === 'visitor') {
          if (increase) {
            newPredVisit = newPredVisit + 1;
          } else {
            newPredVisit = newPredVisit - 1;
          }
        }

        return {
          ...p,
          predictionLocalTeam: isValidateNumber(newPredLocal)
            ? newPredLocal
            : 0,
          predictionVisitingTeam: isValidateNumber(newPredVisit)
            ? newPredVisit
            : 0,
        };
      } else {
        return p;
      }
    });
    setPredictions(newPredictions);
    if (round?.id && tournament?.id) {
      saveStoragePredictions(newPredictions, round.id);
    }
  };

  const handleAuthOpen = (authType?: AuthType) => {
    setAuthModal({ open: true, authType });
  };

  const handleAuthClose = () => {
    setAuthModal({ open: false });
  };

  const handleConfirmResults = async () => {
    /** Hace clcick pero no tiene sesion abierta */
    if (!loggedUser) {
      dispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          status: 'warning',
          message: 'Iniciar sesion',
          description: 'Inicia sesion o registrate para guardar resultados',
        },
      });
      handleAuthOpen('login');
      return;
    }

    if (round?.id && tournament?.id && fixture?.id && loggedUser?.id) {
      /* Si edito sus predicciones hace el post */
      if (secretSavePredictions && predictions.length && isChangePredictions) {
        const decryptSavePredictionsSecret = await decryptData(
          secretSavePredictions,
        );
        const x = decryptSavePredictionsSecret?.split('+')?.[0];
        const savePredictionsSecret =
          decryptSavePredictionsSecret?.split('+')?.[1];
        const dataSavePredictions: DataSavePredictions = {
          predictions: filterNullPredictions(predictions),
          roundId: round?.id,
          savePredictionsSecret,
          fixtureId: fixture?.id,
          userId: loggedUser.id,
        };

        const params: ParamsSavePredictions = { data: dataSavePredictions, x };
        savePredictions(params, {
          onSuccess: (data) => {
            setInitalStatePrediction(predictions);
            /* Si creo el ticket y marco la opcion golden */
            if (!fixtureTicket && fixture?.id && data && isGolden) {
              changeGolden(
                {
                  fixtureId: fixture.id,
                  fixtureTicketType: 'golden',
                },
                {
                  onSuccess: () => {
                    dispatch({
                      type: 'SET_CREDITS',
                      payload:
                        (loggedUser.credits || 0) - (fixture.price || 1000),
                    });
                  },
                },
              );
            }
          },
        });
      }
      /* Si quiere hacer golden en cualquier momento hace otro post */
      if (fixtureTicket && fixtureTicket.ticketType !== 'golden' && isGolden) {
        changeGolden(
          {
            fixtureId: fixture?.id,
            fixtureTicketType: 'golden',
          },
          {
            onSuccess: () => {
              dispatch({
                type: 'SET_CREDITS',
                payload: (loggedUser.credits || 0) - (fixture.price || 1000),
              });
            },
          },
        );
      }
    }
  };

  const handleGoldenTicket = (checked: boolean) => {
    if (!loggedUser) return;
    if (
      (checked && !loggedUser.credits) ||
      (loggedUser.credits && loggedUser.credits < (fixture?.price || 1000))
    ) {
      dispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          status: 'warning',
          message: 'No tienes suficientes creditos',
          description: `Necesitas ${fixture?.price || 1000} creditos para hacer Golden tu Fixture.`,
        },
      });
      return;
    }
    setIsGolden(checked);
  };

  /* ACTUALIZA LAS PREDICCIONES CUANDO CAMBIA DE RONDA O SE LOGUEA UN USUARIO */
  useEffect(() => {
    const storedPredictionsJSON = localStorage.getItem('savePredictions');
    const savePredictionsStorage: PredictionsStorage | undefined =
      storedPredictionsJSON ? JSON.parse(storedPredictionsJSON) : null;
    if (isRoundFinished && round?.id === savePredictionsStorage?.roundId) {
      localStorage.removeItem('savePredictions');
    }
    /* Si se loguea un usuario responsePredictions tiene las predicciones del usuario */
    if (responsePredictions) {
      /* Si existen predicciones y la ronda es la misma seteo las predicciones */
      if (
        responsePredictions?.predictions.length &&
        responsePredictions?.roundId === round?.id
      ) {
        const initialUserPredictions = getInitialPredictions(
          responsePredictions as any,
          round,
        );
        setPredictions(initialUserPredictions);
        setInitalStatePrediction(initialUserPredictions);
        return;
      }
      /* Si se logueo un usuario y no existen predicciones seteo las predicciones del storage y las iniciales como vacias */
      if (!responsePredictions?.predictions?.length) {
        const initialStoragePredictions = getInitialPredictions(
          savePredictionsStorage,
          round,
          true,
        );
        setPredictions(initialStoragePredictions);
        setInitalStatePrediction([]);
        return;
      }
    } else {
      /* Si no existe user logueado no hay responsePredictions y solo seteo las predicciones del storage */
      const initialStoragePredictions = getInitialPredictions(
        savePredictionsStorage,
        round,
        true,
      );
      setPredictions(initialStoragePredictions);
      setInitalStatePrediction(initialStoragePredictions);
    }
  }, [round?.id, responsePredictions]);

  useEffect(() => {
    if (!loggedUser) {
      reset();
    }
    //Si existe round id y no busco predicciones o las predicciones corresponden a otro round id volvera hacer el get
    if (
      round?.id &&
      (!responsePredictions || responsePredictions?.roundId !== round?.id) &&
      secretSavePredictions &&
      loggedUser
    ) {
      fetchPredictions({
        roundId: round.id,
        secretSavePredictions,
        searchMutate,
      });
    }

    /* SI EXISTE ROUND ID PERO NO ESTA SELECCIONADO ESE ROUND ID LO SETEO */
    if (round?.id && !roundId) {
      onChangeRoundId(round.id);
    }
  }, [round?.id, secretSavePredictions, responsePredictions, loggedUser]);

  useEffect(() => {
    if (roundId && round?.id !== roundId) {
      refetchRound();
    }
  }, [roundId]);

  useEffect(() => {
    if (!loggedUser) {
      refetchFixtureTicket();
      setIsGolden(false);
      return;
    }
    if (fixtureId && fixture?.id !== fixtureId && loggedUser?.id) {
      refetchFixtureTicket();
      return;
    }
    if (
      fixtureTicket?.ticketType &&
      fixtureTicket?.ticketType === 'golden' &&
      loggedUser?.id
    ) {
      setIsGolden(true);
    } else {
      setIsGolden(false);
    }
  }, [fixtureId, loggedUser?.id, fixtureTicket]);

  // ESTE USE EFFECT OCULTA EL BOTON DE HACER GOLDEN CUANDO ARRANCA LA FECHA.
  useEffect(() => {
    if (!loggedUser) return setShowGoldenOption(false);
    if (fixtureTicket?.ticketType === 'golden') {
      return setShowGoldenOption(true);
    }
    const now = new Date().getTime();
    const startDate = new Date(fixture?.startDate || 0).getTime();
    const timeUntilInvalid = startDate - now - 5 * 60 * 1000; // Ajusta 5 minutos antes

    if (timeUntilInvalid > 0) {
      const timer = setTimeout(() => {
        setShowGoldenOption(false);
      }, timeUntilInvalid);

      if (loggedUser) {
        setShowGoldenOption(true);
      }
      // Limpia el temporizador si el componente se desmonta o si las dependencias cambian
      return () => clearTimeout(timer);
    } else {
      // Si la fecha ya es inválida, asegura que showGoldenOption sea falso salvo que ya sea golden anteriormente
      setShowGoldenOption(false);
    }
  }, [fixture?.startDate, loggedUser, fixtureTicket]);

  const enableButton =
    fixture?.status !== 'finished' &&
    (isChangePredictions ||
      (fixtureTicket?.ticketType !== 'golden' && isGolden));

  return (
    <div className={styles.container}>
      <div className={styles.containerFixtureSection}>
        <div
          className={`${styles.backgroundImg} `}
          style={{
            backgroundImage: `url(${imageTournament || defaultStadium})`,
          }}
        />

        <div
          className={`${styles.mobileBackgroundImg} `}
          style={{
            backgroundImage: `url(${mobileImageTournament || mobileStadium})`,
          }}
        />
      </div>
      <div className={styles.containerFix}>
        <Column
          className={`${styles.content} ${isGolden && styles.goldenContent}`}
        >
          <Ticket
            tournamentName={tournament?.name}
            fixtureName={fixture?.name}
            isFixtureFinishedOpen={isFixtureFinishedOpen}
            isGolden={isGolden}
            roundSelected={round}
            rounds={fixture?.rounds}
            predictions={predictions}
            onChangePrediction={onChangePrediction}
            onChangeRoundId={onChangeRoundId}
            isLoading={isLoading}
          />

          {showGoldenOption && (
            <GoldSwitch
              checked={isGolden}
              onChange={handleGoldenTicket}
              currentTicketType={fixtureTicket?.ticketType}
            />
          )}

          {!isRoundFinished ? (
            <CustomTooltip
              title={'No hay cambios pendientes'}
              disabled={
                isChangePredictions ||
                (fixtureTicket?.ticketType !== 'golden' && isGolden)
              }
            >
              <Button
                className={`${styles.buttonConfirm} ${isGolden && styles.buttonConfirmGolden} ${!enableButton && styles.disabled}`}
                onClick={handleConfirmResults}
                disabled={!enableButton}
                loading={isPendingSave || isPendingGolden}
              >
                Guardar Fixture
              </Button>
            </CustomTooltip>
          ) : null}
        </Column>

        {authModal.authType && authModal.open ? (
          <AuthModal
            open={authModal.open}
            authType={authModal.authType}
            handleClose={handleAuthClose}
          />
        ) : null}
      </div>
    </div>
  );
};

export default FixtureSection;
