import React from 'react';
import { Button, Card, Divider } from '../../../../../../../commons';
import { Column, Flex } from '../../../../../../../../utils/layout';
import ItemList from '../../../../../../instructions/components/instructionItemList/ItemList';
import styles from './packCard.module.css';
import { formatPrice } from '../../../../../../../../utils';

type PackCardProps = {
  type?: string;
  credits?: number;
  price?: number;
  realPrice?: number;
  itemsList?: string[];
  onClick?: () => void;
};

const PackCard: React.FC<PackCardProps> = ({
  credits,
  itemsList,
  price,
  type,
  onClick,
  realPrice,
}) => {
  return (
    <Card className={styles.card}>
      <Flex className={styles.header}>
        <span className={styles.type}>Pack {type}</span>
        <Column className={styles.pricesMobile}>
          <span className={styles.realPrice}>{formatPrice(realPrice)}</span>
          <span className={styles.price}>{formatPrice(price)}</span>
        </Column>
      </Flex>

      <Column className={styles.containerPack}>
        <Column className={styles.credits}>
          <i className='fas fa-coins'></i>
          <div>{credits} Creditos</div>
        </Column>

        <Column className={styles.prices}>
          <span className={styles.realPrice}>{formatPrice(realPrice)}</span>
          <span className={styles.price}>{formatPrice(price)}</span>
        </Column>

        <Button className={styles.button} size='large' onClick={onClick}>
          Comprar Ahora
        </Button>
      </Column>

      <Divider className={styles.divider} />
      <Column>
        {itemsList?.map((item, index) => (
          <ItemList
            key={index}
            type='icon'
            icon={<i className='fa-solid fa-check' />}
            className={styles.list}
          >
            {item}
          </ItemList>
        ))}
      </Column>
    </Card>
  );
};

export default PackCard;
