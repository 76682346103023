import moment from 'moment-timezone';

const generateXAK = async ({ str1, str2 }: { str1?: string; str2: string }) => {
  const date = moment()
    .tz('America/Argentina/Buenos_Aires')
    .format('YYYY-MM-DD');
  const x1 = str1 || date;
  const combinedString = `${x1}${str2}`;
  const encoder = new TextEncoder();
  const data = encoder.encode(combinedString);

  const api = await crypto.subtle.digest('SHA-256', data).then((hashBuffer) => {
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const apiKey = hashArray
      .map((byte) => byte.toString(16).padStart(2, '0'))
      .join('');

    return apiKey;
  });

  return api;
};

export default generateXAK;
