import { Modal } from 'antd';
import React, { Children } from 'react';

import styles from './darkModal.module.css';
import { CloseOutlined } from '@ant-design/icons';
import { Column, Flex } from '../../../utils/layout';
import Button from '../button/Button';
import useWriting from '../../../copywriting/useWriting';

type DarkModalProps = {
  open?: boolean;
  onClose?: () => void;
  title?: string;
  children: React.ReactNode;
  footer?: boolean | null;
  onConfirm?: () => void;
  onConfirmText?: string;
  onCancelText?: string;
  className?: string;
  contentClassName?: string;
  isGolden?: boolean;
};

const DarkModal: React.FC<DarkModalProps> = ({
  open,
  onClose,
  title,
  children,
  footer,
  onConfirm,
  onCancelText,
  onConfirmText,
  className,
  contentClassName,
  isGolden,
}) => {
  const writing = useWriting();
  return (
    <Modal
      open={open}
      className={`${className && className} ${styles.modal}`}
      closeIcon={false}
      footer={null}
    >
      <Column
        className={`${styles.content} ${contentClassName && contentClassName}`}
      >
        <Flex className={styles.header}>
          <span className={isGolden ? styles.titleGolden : ''}>
            {title || ''}
          </span>
          <div className={styles.closeIcon} onClick={onClose}>
            <CloseOutlined />
          </div>
        </Flex>

        {children}
        {footer && (
          <Flex className={styles.buttons}>
            <Button type='secondary' size='small' onClick={onClose}>
              {onCancelText || writing.authHeader.confirmModal.cancelButton}
            </Button>
            <Button size='small' onClick={onConfirm}>
              {onConfirmText || 'Confirmar'}
            </Button>
          </Flex>
        )}
      </Column>
    </Modal>
  );
};

export default DarkModal;
