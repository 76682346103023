import React, { useEffect, useState } from 'react';
import { SpinerScreen } from '../../commons';
import { useGetFixture, useGetTournament } from '../../serverStore/queries';
import FixtureSection from './components/fixtureSection/FixtureSection';
import ResultSection from './components/resultsSection/ResultSection';
import { WinModal } from '../../modals';
import useClientStore from '../../clientStore/useClientStore';
import { WinnersData } from '../../../utils/winners/winners';
import FixtureFinished from '../../modals/fixtureFinished/FixtureFinished';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { useNavigate } from 'react-router-dom';
import styles from './home.module.css';
import { Column } from '../../../utils/layout';

const Home: React.FC = () => {
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [openWhatsApp, setOpenWhatsApp] = useState(false);

  const {
    fixtureSelected: { tournamentId, fixtureId },
    dispatch,
    loggedUser,
  } = useClientStore();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const tournamentParamId = searchParams.get('tournamentId');
  const selectedTournamentId = tournamentParamId
    ? Number(tournamentParamId)
    : tournamentId;

  const { data: tournamentData, isError } = useGetTournament({
    tournamentId: selectedTournamentId,
    from: 'home',
  });
  const imageTournament = tournamentData?.image;

  const { data: fixtureData, isLoading: loadingGetFixtures } = useGetFixture({
    tournamentId: tournamentId || tournamentData?.id,
    fixtureId,
  });

  const [fixtureFinishedOpen, setFixtureFinishedOpen] = useState<{
    open: boolean;
    winners?: WinnersData;
  }>({ open: false });

  const [winModalOpen, setWinModalOpen] = useState<{
    open: boolean;
    winners?: WinnersData;
  }>({ open: false });

  const isLoading = loadingGetFixtures || isImageLoading;

  useEffect(() => {
    if (tournamentParamId) {
      dispatch({
        type: 'SET_TOURNAMENT_ID',
        payload: Number(tournamentParamId),
      });
      return navigate('/', { replace: true });
    }
    if (tournamentData?.id && tournamentId !== tournamentData?.id) {
      dispatch({
        type: 'SET_TOURNAMENT_ID',
        payload: tournamentData?.id,
      });
    }
  }, [tournamentParamId, tournamentData?.id]);

  useEffect(() => {
    if (fixtureData?.id && fixtureId !== fixtureData?.id) {
      dispatch({
        type: 'SET_FIXTURE_ID',
        payload: fixtureData?.id,
      });
    }
  }, [fixtureData?.id]);

  useEffect(() => {
    if (imageTournament) {
      setIsImageLoading(true);
      const img = new Image();
      img.src = imageTournament;
      img.onload = () => setIsImageLoading(false);
    }
  }, [imageTournament]);

  useEffect(() => {
    if (isError) {
      dispatch({
        type: 'SET_TOURNAMENT_ID',
        payload: undefined,
      });
    }
  }, [isError]);

  /** Maneja el cambio de fixture */
  /*   useEffect(() => {
    if (fixtureData?.tournament?.id !== (tournamentId || tournamentData?.id)) {
      refetchGetFixture();
    }
  }, [fixtureId, tournamentId, tournamentData?.id]); */

  /** Maneja el cambio de ronda */
  /*   useEffect(() => {
    if (fixtureData?.id) {
      refetchGetRounds();
    }
  }, [fixtureData?.id]);
 */
  /** Muestra o no el modal del formulario para el usuario que gano */
  /*   useEffect(() => {
    const storageClaimedPrize = localStorage.getItem('claimedPrize');
    const storageClaimPrizeParse: StorageClaimPrizeModal | undefined =
      storageClaimedPrize ? JSON.parse(storageClaimedPrize) : undefined;
    const findWinners = winners?.find(
      (w) =>
        w.nextFixtureName === fixtureData?.name ||
        w.nextFixtureName === 'lastFixture', //Uso este para mostrar el ultimo ganador de la ultima fecha
    );
    const isThisUserWin = findWinners?.info.find(
      (u) => u.email === loggedUser?.email,
    );
    if (
      findWinners &&
      (!storageClaimPrizeParse ||
        !storageClaimPrizeParse[findWinners?.fixture.fixtureName]) &&
      isThisUserWin
    ) {
      setWinModalOpen({ open: true, winners: findWinners });
    }
  }, [winners, loggedUser, tournamentData?.id, fixtureData?.id]); */

  /** Muestra el o los ganadores de la fecha anterior */
  /*   useEffect(() => {
    const storageShowWinnerModal = localStorage.getItem('showWinnerModal');
    const storageShowWinnerModalParse: StorageShowModalWinner | undefined =
      storageShowWinnerModal ? JSON.parse(storageShowWinnerModal) : undefined;
    const findWinners = winners?.find(
      (w) =>
        w.nextFixtureName === fixtureData?.name ||
        w.nextFixtureName === 'lastFixture', //Uso este para mostrar el ultimo ganador de la ultima fecha
    );

    if (
      findWinners &&
      (!storageShowWinnerModalParse ||
        !storageShowWinnerModalParse[findWinners?.fixture.fixtureName]) &&
      (fixtureData?.status === 'toStart' ||
        fixtureData?.status === 'inProgress' ||
        fixtureData?.status === 'created' ||
        findWinners.nextFixtureName === 'lastFixture')
    ) {
      setFixtureFinishedOpen({ open: true, winners: findWinners });
    }
  }, [winners, fixtureData?.name]); */

  if (isLoading)
    return (
      <SpinerScreen
        style={{
          position: 'absolute',

          zIndex: 1,
        }}
      />
    );
  return (
    <div>
      {/* SECCION PARA PONER RESULTADOS A LOS PARTIDOS */}
      <FixtureSection
        fixture={fixtureData}
        tournament={tournamentData}
        isFixtureFinishedOpen={fixtureFinishedOpen.open}
      />

      {/* SECCION PARA VER LOS RESULTADOS DE CADA RONDA  */}
      <ResultSection fixture={fixtureData} tournament={tournamentData} />

      {winModalOpen.open && winModalOpen.winners ? (
        <WinModal
          open={winModalOpen.open}
          winners={winModalOpen.winners}
          onClose={() => setWinModalOpen({ open: false })}
        />
      ) : null}

      {fixtureFinishedOpen.open && fixtureFinishedOpen.winners ? (
        <FixtureFinished
          open={fixtureFinishedOpen.open}
          winners={fixtureFinishedOpen.winners}
          onClose={() => setFixtureFinishedOpen({ open: false })}
        />
      ) : null}
      <Column className={styles.whatsapp}>
        <FloatingWhatsApp
          onClick={() => {
            setTimeout(() => {
              setOpenWhatsApp(true);
            }, 2000);
          }}
          onClose={() => setOpenWhatsApp(false)}
          accountName='Fixtuwin'
          phoneNumber='+5491132274036'
          statusMessage='En Linea'
          avatar='https://firebasestorage.googleapis.com/v0/b/fixtuwin.appspot.com/o/logoPelota.png?alt=media&token=12977e31-9e3e-4b17-82f0-9dc496b1c08c'
          chatMessage={`Hola ${loggedUser?.name || ''}! 🤝 
        \nTe invitamos a unirte a nuestro grupo de WhatsApp para formar parte de nuestra comunidad:
        
        \nSi tienes alguna duda o consulta, puedes escribirnos. Con gusto te ayudaremos!`}
          chatboxHeight={500}
          placeholder='Escribe un mensaje..'
          allowClickAway
          allowEsc
        />
        {openWhatsApp ? (
          <span
            onClick={() =>
              window.open(
                'https://chat.whatsapp.com/CKel3T3amNg9T3r0J8Mv4G',
                '_blank',
              )
            }
            className={styles.link}
          >
            Unirme al grupo
          </span>
        ) : null}
      </Column>
    </div>
  );
};

export default Home;
