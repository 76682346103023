import CryptoJS from 'crypto-js';
import logger from '../logger/logger';
import generateXAK from './generateXAK';
import handleErrorReponse from './handleErrorReponse';

const decryptData = async (cryptData: any, str1?: string) => {
  if (!cryptData || typeof cryptData !== 'string') return cryptData;
  const a = cryptData.split('-')?.[0].trim();
  const b = cryptData.split('-')?.[1].trim();
  const c = await generateXAK({ str1, str2: b });
  try {
    const bytes = CryptoJS.AES.decrypt(a, c);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    const e = handleErrorReponse(error);
    logger(e);
    return null;
  }
};

export default decryptData;
