import { useQuery } from '@tanstack/react-query';
import { StatusTournament } from '../../../../types/tournament.types';
import axiosFetch from '../../../../utils/axiosConfig/axiosFetch';

export type ResponseFixtureNames = {
  id: number;
  name: string;
  status: StatusTournament;
}[];
const getFixtureNames = async (
  tournamentId?: number,
): Promise<ResponseFixtureNames> => {
  const response: ResponseFixtureNames = await axiosFetch(
    `/fixture/names/${tournamentId}`,
  );
  return response;
};

export default function useGetFixtureNames(tournamentId?: number) {
  return useQuery({
    queryKey: ['fixture-names', tournamentId],
    queryFn: () => getFixtureNames(tournamentId),
    enabled: !!tournamentId,
    staleTime: 1000 * 60 * 15, // Mantiene la data "fresca" por 15 minutos
    gcTime: 1000 * 60 * 30, // Mantiene los datos en caché por 30 minutos
  });
}
