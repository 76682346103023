import { Modal } from 'antd';
import React from 'react';
import { Column, Flex } from '../../../utils/layout';
import styles from './showPredictionModal.module.css';
import FixturePredictionResult from './components/fixturePredictionResult/FixturePredictionResult';
import TournamentPredictionResult from './components/tournamentPredictionResult/TournamentPredictionResult';
import DarkModal from '../../commons/darkModal/DarkModal';
import { GenericTickets } from '../../../types/ticket.types';

export type TicketName = 'fixtureTicket' | 'tournamentTicket';
interface ITicketResultsModalProps {
  ticketName: TicketName;
  email: string;
  open?: boolean;
  onClose?: () => void;
  ticket: GenericTickets;
}

const ShowPredictionsModal: React.FC<ITicketResultsModalProps> = ({
  open,
  onClose,
  ticketName,
  email,
  ticket,
}) => {
  const isGolden = ticket.ticketType === 'golden';
  return (
    <DarkModal
      open={open}
      onClose={onClose}
      footer={null}
      contentClassName={`${styles.contentClassName} ${isGolden && styles.contentGolden}`}
      title={`Predicciones de ${ticket.user?.name}`}
      isGolden={isGolden}
    >
      <Column className={styles.content}>
        {ticketName === 'fixtureTicket' ? (
          <FixturePredictionResult email={email} isGolden={isGolden} />
        ) : null}
        {ticketName === 'tournamentTicket' ? (
          <TournamentPredictionResult email={email} />
        ) : null}
      </Column>
      <Flex className={styles.totalPoints}>
        <span>Total:</span>
        {/* CUADRO DE PUNTOS */}

        <div
          className={`${styles.containerPoints} ${isGolden && styles.containerPointsGolden}`}
        >
          {ticket?.resultPoints ? '+' + ticket.resultPoints : 0}
        </div>
      </Flex>
      <span className={styles.note}>
        (*) Los puntos de los partidos se agregarán al total únicamente al
        finalizar cada partido.
      </span>
    </DarkModal>
  );
};

export default ShowPredictionsModal;
