import React from 'react';

import styles from './roundNames.module.css';
import { RoundAttributes } from '../../../../../types/round.types';
import { Flex } from '../../../../../utils/layout';
import Tag from '../../../tag/Tag';
import { capitalizeFirstLetter } from '../../../../../utils';

interface IRoundNameProps {
  rounds: RoundAttributes[];
  onChangeRoundId?: (roundId?: number) => void;
  isGolden?: boolean;
  roundIdSelected: number | undefined;
}

const RoundName: React.FC<IRoundNameProps> = ({
  rounds,
  onChangeRoundId,
  isGolden,
  roundIdSelected,
}) => {
  const sortedRounds = [...rounds].sort((a, b) => (a.id ?? 0) - (b.id ?? 0));

  return (
    <Flex className={styles.containerTagNames}>
      {sortedRounds.map((r) => {
        const isChecked = roundIdSelected === r.id;
        return (
          <Tag
            label={capitalizeFirstLetter(r.name) || r.name}
            key={r.id}
            onClick={() => onChangeRoundId?.(r?.id)}
            className={`${styles.tag} ${isGolden && styles.golden} ${isChecked && styles.checked} ${isChecked && isGolden && styles.checkedGolden}`}
          />
        );
      })}
    </Flex>
  );
};

export default RoundName;
