import React, { useEffect, useState } from 'react';
import styles from './depositSection.module.css';
import { Column, Flex } from '../../../../../../utils/layout';
import { Button, Divider } from '../../../../../commons';
import { formatPrice } from '../../../../../../utils';
import PackCard from './components/packCard/PackCard';
import { Input } from 'antd';
import { CheckoutModal } from '../../../../../modals';

import {
  bookCreditPacks,
  CreditsPacksId,
  defaultPriceFixture,
} from '../../../../../../types/credit.types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useClientStore from '../../../../../clientStore/useClientStore';

const isValidAmount = (creditAmount: number) => {
  if (creditAmount != undefined) {
    return (
      creditAmount >= defaultPriceFixture &&
      creditAmount <= defaultPriceFixture * 10
    );
  }
  return false;
};

const DepositSection: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { dispatch } = useClientStore();

  // si falla el request viene con estos params y abre el modal de compra nuevamente
  const creditAmountParam = searchParams.get('creditAmount');
  const packIdParam = searchParams.get('packId');

  // si no falla el request viene con estos params para mostrar un mensaje de exito
  const paymentIdParam = searchParams.get('payment_id');
  const statusParam = searchParams.get('status');
  const successCreditsParam = searchParams.get('credits');

  const [inputCredit, setInputCredit] = useState<number>(0);
  const [openCheckoutModal, setOpenCheckoutModal] = useState<{
    open: boolean;
    creditAmount?: number;
    price?: number;
    packId?: CreditsPacksId;
  }>({
    open: false,
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputCredit(Number(e.target.value));
  };

  const onBuyClick = async ({
    creditAmount,
    price,
    packId,
  }: {
    creditAmount?: number;
    price?: number;
    packId?: CreditsPacksId;
  }) => {
    if (creditAmount && price && isValidAmount(creditAmount)) {
      setOpenCheckoutModal({
        open: true,
        creditAmount,
        price,
        packId,
      });
    }
  };

  // Verifica parámetros de la URL y abre el modal si son válidos
  useEffect(() => {
    if (!creditAmountParam) return;
    const creditAmount = creditAmountParam
      ? Number(creditAmountParam)
      : undefined;
    const packId = packIdParam as CreditsPacksId | undefined;

    if (creditAmount && isValidAmount(creditAmount)) {
      // Busca el precio del pack si `packId` está presente
      const price =
        packId && bookCreditPacks[packId]
          ? bookCreditPacks[packId]
          : creditAmount * 1.2;

      setOpenCheckoutModal({
        open: true,
        creditAmount,
        price,
        packId,
      });
    }
  }, [creditAmountParam, packIdParam]);

  // Verificar si el pago fue aprobado
  useEffect(() => {
    if (paymentIdParam && statusParam === 'approved' && successCreditsParam) {
      dispatch({
        type: 'SET_NOTIFICATION',
        payload: {
          status: 'success',
          message: `¡Carga exitosa! Tus ${successCreditsParam} créditos han sido agregados.`,
        },
      });

      // Limpiar parámetros de la URL
      navigate('/user/credits', { replace: true });
    }
  }, [paymentIdParam, statusParam, navigate]);

  return (
    <Column className={styles.container}>
      <span className={styles.title}>
        Consigue tu pack de créditos en oferta ahora mismo
      </span>
      <span className={styles.description}>
        Compite en los fixtures golden y gana los créditos acumulados en cada
        fecha. ¡Decídete ahora y añade emoción a tus predicciones!
      </span>
      <Flex className={styles.packs}>
        <PackCard
          type='Basico'
          credits={defaultPriceFixture * 3}
          price={bookCreditPacks.PA3000}
          realPrice={defaultPriceFixture * 3 * 1.2}
          itemsList={[
            `Ahorras ${formatPrice(defaultPriceFixture * 3 * 1.2 - bookCreditPacks.PA3000)} con este Pack.`,
            'Ideal para participar en 3 Fixtures Golden.',
          ]}
          onClick={() =>
            onBuyClick({
              creditAmount: defaultPriceFixture * 3,
              price: bookCreditPacks.PA3000,
              packId: 'PA3000',
            })
          }
        />

        <PackCard
          type='Intermedio'
          credits={defaultPriceFixture * 5}
          price={bookCreditPacks.PA5000}
          realPrice={defaultPriceFixture * 5 * 1.2}
          itemsList={[
            `Ahorras ${formatPrice(defaultPriceFixture * 5 * 1.2 - bookCreditPacks.PA5000)} con este Pack.`,
            'Ideal para participar en 5 Fixtures Golden.',
          ]}
          onClick={() =>
            onBuyClick({
              creditAmount: defaultPriceFixture * 5,
              price: bookCreditPacks.PA5000,
              packId: 'PA5000',
            })
          }
        />

        <PackCard
          type='Premium'
          credits={defaultPriceFixture * 10}
          price={bookCreditPacks.PA10000}
          realPrice={defaultPriceFixture * 10 * 1.2}
          itemsList={[
            `Ahorras ${formatPrice(defaultPriceFixture * 10 * 1.2 - bookCreditPacks.PA10000)} con este Pack.`,
            'Ideal para participar en 10 Fixtures Golden.',
          ]}
          onClick={() =>
            onBuyClick({
              creditAmount: defaultPriceFixture * 10,
              price: bookCreditPacks.PA10000,
              packId: 'PA10000',
            })
          }
        />
      </Flex>

      <Divider className={styles.divider} />

      <Column className={styles.customPack}>
        <span className={styles.title}>
          ¿No encontraste el pack que necesitas?
        </span>
        <span className={styles.description}>
          Personaliza el monto de tu recarga. Ingresa la cantidad de créditos
          que deseas cargar y recarga de forma personalizada
        </span>
        <Flex className={styles.inputContainer}>
          <Input
            className={styles.input}
            placeholder='Ingrese la cantidad de créditos'
            type='number'
            min={defaultPriceFixture}
            max={defaultPriceFixture * 10}
            value={inputCredit || ''}
            onChange={handleInputChange}
          />
          <Button
            disabled={!inputCredit || !isValidAmount(inputCredit)}
            className={styles.button}
            onClick={() =>
              onBuyClick({
                creditAmount: inputCredit,
                price: inputCredit * 1.2,
              })
            }
          >
            Comprar Ahora
          </Button>
        </Flex>
        {inputCredit && isValidAmount(inputCredit) && (
          <span className={styles.cost}>
            COSTO: <strong>{formatPrice(inputCredit * 1.2)}</strong>
          </span>
        )}
        <span className={styles.note}>
          {`La carga mínima es de ${defaultPriceFixture} créditos y la carga máxima es de ${defaultPriceFixture * 10}
          créditos`}
        </span>
      </Column>

      {openCheckoutModal.open &&
        openCheckoutModal.creditAmount &&
        openCheckoutModal.price && (
          <CheckoutModal
            open={openCheckoutModal.open}
            onClose={() =>
              setOpenCheckoutModal({
                open: false,
              })
            }
            creditAmount={openCheckoutModal.creditAmount}
            price={openCheckoutModal.price}
            packId={openCheckoutModal.packId}
            onMpButtonClick={() =>
              setOpenCheckoutModal({
                open: false,
              })
            }
          />
        )}
    </Column>
  );
};

export default DepositSection;
