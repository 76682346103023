import React from 'react';
import { Flex } from '../../../utils/layout';
import styles from './buttonsGroup.module.css';

export type OptionButtonGroup = { label: string; value: string };

type ButtonsGroupProps = {
  onClick: (selectedOption: OptionButtonGroup) => void;
  options: OptionButtonGroup[];
  selectedOption?: OptionButtonGroup;
  classNameButton?: string;
  className?: string;
};

const ButtonsGroup: React.FC<ButtonsGroupProps> = ({
  onClick,
  options,
  selectedOption,
  classNameButton,
  className,
}) => {
  const [buttonSelected, setButtonSelected] = React.useState<OptionButtonGroup>(
    options[0],
  );

  const optionSelected = selectedOption || buttonSelected;

  const handleButtonClick = (option: OptionButtonGroup) => {
    setButtonSelected(option); // Actualiza el estado del botón seleccionado
    onClick(option); // Llama a la función onClick pasando la opción seleccionada
  };

  return (
    <Flex className={`${styles.buttons} ${className && className}`}>
      {options.map((option, index) => {
        // Determina la clase según la posición y la cantidad de opciones
        const isFirst = index === 0;
        const isLast = index === options.length - 1;
        const isMiddle = !isFirst && !isLast && options.length > 2;

        return (
          <span
            key={option.value}
            className={`${styles.button} 
                      ${isFirst ? styles.buttonLeft : ''} 
                      ${isMiddle ? styles.buttonMiddle : ''} 
                      ${isLast ? styles.buttonRight : ''} 
                      ${optionSelected.value === option.value ? styles.buttonActive : ''} ${classNameButton && classNameButton}`}
            onClick={() => handleButtonClick(option)}
          >
            {option.label}
          </span>
        );
      })}
    </Flex>
  );
};

export default ButtonsGroup;
