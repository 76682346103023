import { useQuery } from '@tanstack/react-query';
import { RoundAttributes } from '../../../../types/round.types';
import axiosFetch from '../../../../utils/axiosConfig/axiosFetch';

type Params = {
  roundId?: number;
  fixtureId?: number;
  tournamentId?: number;
  allRounds?: boolean;
  includeTournament?: boolean;
  includeMatch?: boolean;
  includeTeam?: boolean;
  enabledRequest?: boolean;
};

const getRound = async (params: Params): Promise<RoundAttributes> => {
  const {
    roundId,
    fixtureId,
    tournamentId,
    allRounds,
    includeMatch,
    includeTeam,
    includeTournament,
  } = params;
  let queryString = '';

  if (fixtureId) queryString += `fixtureId=${fixtureId}&`;
  if (tournamentId) queryString += `tournamentId=${tournamentId}&`;
  if (allRounds) queryString += `allRounds=${allRounds}&`;
  if (includeMatch) queryString += `includeMatch=${includeMatch}&`;
  if (includeTeam) queryString += `includeTeam=${includeTeam}&`;
  if (includeTournament)
    queryString += `includeTournament=${includeTournament}&`;

  // Eliminar el último "&" si existe
  queryString = queryString.replace(/&$/, '');

  const response: RoundAttributes = await axiosFetch(
    `/round/id/${roundId || ''}?includeTeam=true&${queryString}`,
  );
  return response;
};

export default function useGetRound(params: Params) {
  return useQuery({
    queryKey: ['round', params?.roundId || 'last', params?.fixtureId],
    queryFn: () => getRound(params),
    staleTime: 1000 * 60 * 60 * 24, // Mantiene la data "fresca" por 60 minutos * 24 = 1 dia
    gcTime: 1000 * 60 * 60 * 24, // Mantiene los datos en caché por 60 minutos * 24 = 1 dia
    enabled:
      params.enabledRequest != undefined
        ? params.enabledRequest
        : !!params.fixtureId,
  });
}
