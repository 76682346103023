import React, { useEffect, useState } from 'react';
import { useGetAllFixtureTickets } from '../../../../../../serverStore/queries';
import { FixtureAttributes } from '../../../../../../../types/fixture.types';
import { validateStartDate } from '../../../../../../../utils/hooks/handleDate';
import useClientStore from '../../../../../../clientStore/useClientStore';
import PositionsTable from '../homePositionsTable/HomePositionsTable';
import ShowMoreFixturesTicket from './components/ShowMoreFixturesTicket';
import { ButtonsGroup } from '../../../../../../commons';
import { OptionButtonGroup } from '../../../../../../commons/buttonsGroup/ButtonsGroup';
import styles from './fixtureResult.module.css';
import { Spin } from 'antd';

interface IFixtureResultProps {
  fixture: FixtureAttributes;
}

const FixtureResult: React.FC<IFixtureResultProps> = ({ fixture }) => {
  const {
    fixtureSelected: { fixtureId, tournamentId },
  } = useClientStore();
  const selectedFixtureId = fixture?.id || fixtureId;
  const [onlyGolden, setOnlyGolden] = useState(false);

  const {
    data,
    refetch,
    isLoading: isLoadingTickets,
  } = useGetAllFixtureTickets({
    groupped: true,
    page: '1',
    resultsPerPage: '10',
    fixtureId: selectedFixtureId ? Number(selectedFixtureId) : undefined,
    enabledRequest: !!selectedFixtureId,
    tournamentId: tournamentId ? Number(tournamentId) : undefined,
    onlyGolden,
  });
  const [openTableModal, setOpenTableModal] = useState(false);

  const isStarted = !validateStartDate(fixture?.startDate);

  const options: OptionButtonGroup[] = [
    {
      value: 'all',
      label: 'Mostrar Todos',
    },
    {
      value: 'golden',
      label: 'Mostrar Goldens',
    },
  ];

  const handleCloseTableModal = () => {
    setOpenTableModal(false);
  };

  const handleshowMoreTickets = () => {
    setOpenTableModal(true);
  };

  const handleButtons = (op: OptionButtonGroup) => {
    if (op.value === 'golden') {
      setOnlyGolden(true);
      return;
    }

    setOnlyGolden(false);

    return;
  };

  useEffect(() => {
    if (fixtureId !== fixture?.id) {
      refetch();
    }
  }, [fixtureId, fixture]);
  if (!data?.results) return null;

  if (isLoadingTickets) return <Spin size='large' />;

  return (
    <>
      <ButtonsGroup
        onClick={handleButtons}
        className={styles.buttonsGroup}
        classNameButton={styles.buttonGroup}
        selectedOption={onlyGolden ? options[1] : options[0]}
        options={options}
      />

      {/* TABLA DE POSICIONES */}
      <PositionsTable
        isStarted={isStarted}
        grouppedTickets={data.results}
        totalTickets={data.count}
        onClickShowMoreTickets={handleshowMoreTickets}
        ticketName={'fixtureTicket'}
        currentPage={1}
      />

      {/* TABLA PARA MOSTRAR MAS TICKETS */}
      {openTableModal && fixture?.id ? (
        <ShowMoreFixturesTicket
          fixtureId={fixture?.id}
          tournamentId={fixture?.tournamentId || tournamentId}
          openTableModal={openTableModal}
          onClose={handleCloseTableModal}
          isStarted={isStarted}
          fixtureName={fixture?.name}
        />
      ) : null}
    </>
  );
};

export default FixtureResult;
