import { useQuery } from '@tanstack/react-query';

import axiosFetch from '../../../../../utils/axiosConfig/axiosFetch';
import {
  GroupedTickets,
  ResponsePaginatedFixtureTickets,
} from '../../../../../types/ticket.types';
import { decryptGruppedTickets } from '../../../../../utils';

type TicketQueryParams = {
  page?: string;
  resultsPerPage?: string;
  searchText?: string;
  groupped?: boolean;
  enabledRequest?: boolean;
  tournamentId?: number;
};
const getTournamentTickets = async (
  params: TicketQueryParams,
): Promise<ResponsePaginatedFixtureTickets> => {
  const { groupped, page, resultsPerPage, searchText, tournamentId } = params;
  let queryString = '';
  if (page) queryString += `page=${page}&`;
  if (resultsPerPage) queryString += `resultsPerPage=${resultsPerPage}&`;
  if (searchText) queryString += `searchText=${searchText}&`;
  if (groupped) queryString += `groupped=${groupped}&`;
  if (tournamentId) queryString += `tournamentId=${tournamentId}&`;

  // Eliminar el último "&" si existe
  queryString = queryString.replace(/&$/, '');

  const response: ResponsePaginatedFixtureTickets = await axiosFetch(
    `/ticket/tournament/all?${queryString}`,
  );
  const decryptedUserTickets: GroupedTickets | undefined =
    await decryptGruppedTickets(response?.results);
  return { ...response, results: decryptedUserTickets };
};

export default function useGetAllTournamentTickets(params: TicketQueryParams) {
  return useQuery({
    queryKey: [
      'tickets-tournament',
      params.tournamentId,
      params.page,
      params.resultsPerPage,
    ],
    queryFn: () => getTournamentTickets(params),
    enabled: params.enabledRequest,
    staleTime: 1000 * 60 * 1, // Mantiene la data "fresca" por 1 minutos
    gcTime: 1000 * 60 * 1, // Mantiene los datos en caché por 1 minutos
  });
}
