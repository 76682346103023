export type CreditsPacksId = 'PA3000' | 'PA5000' | 'PA10000';
export const defaultPriceFixture = 2000;

export type MpPreferenceResponse = {
  url: string;
  id: string;
};

export const bookCreditPacks: { [key in CreditsPacksId]: number } = {
  PA3000: defaultPriceFixture * 3 * 1.05,
  PA5000: defaultPriceFixture * 5 * 1.1,
  PA10000: defaultPriceFixture * 10 * 1,
};
