import React from 'react';
import { DarkModal, PositionsTable } from '../../commons';
import { TicketName } from '../showPredictionsModal/ShowPredictionsModal';
import { GroupedTickets } from '../../../types/ticket.types';
import Pagination from '../../commons/pagination/Pagination';
import styles from './showMoreTicketsModal.module.css';
import { Column } from '../../../utils/layout';

type ShowMoreTicketsModalProps = {
  open?: boolean;
  onClose?: () => void;
  grouppedTickets?: GroupedTickets;
  totalTickets?: number;
  isLoading: boolean;
  isStarted: boolean;
  currentPage: number;
  onChangePagination: (newPage: number) => void;
  ticketName: TicketName;
  title: string;
};

const ShowMoreTicketsModal: React.FC<ShowMoreTicketsModalProps> = ({
  isStarted,
  totalTickets,
  grouppedTickets,
  currentPage,
  isLoading,
  open,
  onClose,
  title,
  onChangePagination,
  ticketName,
}) => {
  const resultsPerPage = 15;

  return (
    <DarkModal
      open={open}
      onClose={onClose}
      title={title}
      className={styles.modal}
    >
      <Column className={styles.container}>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <>
            {grouppedTickets && totalTickets && (
              <PositionsTable
                isStarted={isStarted}
                grouppedTickets={grouppedTickets}
                ticketName={ticketName}
                currentPage={currentPage}
              />
            )}
            {totalTickets != undefined && totalTickets > resultsPerPage ? (
              <div className={styles.containerPagination}>
                <Pagination
                  onPageChange={onChangePagination}
                  current={currentPage}
                  total={totalTickets}
                  itemsPerPage={resultsPerPage}
                />
              </div>
            ) : null}
          </>
        )}
      </Column>
    </DarkModal>
  );
};

export default ShowMoreTicketsModal;
