import React, { useEffect, useMemo, useState } from 'react';

import {
  useGetRound,
  useGetSavePredictionSecret,
  useGetTournament,
} from '../../../../serverStore/queries';
import useClientStore from '../../../../clientStore/useClientStore';
import { useSearchUserPrediction } from '../../../../serverStore/mutations';
import { fetchPredictions } from '../../../../../utils';
import Ticket from '../../../../commons/ticket/Ticket';
import { SimplePrediction } from '../../../../screens/home/components/fixtureSection/FixtureSection';

interface ITournamentPredictionResultProps {
  email: string;
  tournamentId?: number;
  roundId?: number;
}

const TournamentPredictionResult: React.FC<
  ITournamentPredictionResultProps
> = ({ email, roundId, tournamentId }) => {
  const { loggedUser, fixtureSelected } = useClientStore();
  const [roundIdState, setRoundIdState] = useState<number | undefined>(
    undefined,
  );

  const selectedTournamentId = tournamentId || fixtureSelected.tournamentId;
  const selectedRoundId = roundId || roundIdState || fixtureSelected.roundId;

  const { data: secretSavePredictions, isLoading: loadingGetSecret } =
    useGetSavePredictionSecret();

  const {
    data: tournamentData,
    isLoading: loadingGetTournament,
    refetch: refetchGetTournament,
  } = useGetTournament({
    tournamentId: selectedTournamentId,
    includeRounds: true,
  });

  const {
    data: round,
    isLoading: loadingGetRounds,
    refetch: refetchRound,
  } = useGetRound({
    roundId: selectedRoundId,
    tournamentId: selectedTournamentId,
    enabledRequest: !!selectedTournamentId && !!loggedUser,
  });

  const {
    data: responsePredictions,
    mutate: searchMutate,
    isPending: loadingGetPredictions,
  } = useSearchUserPrediction();

  const isLoading =
    loadingGetTournament ||
    loadingGetRounds ||
    loadingGetSecret ||
    loadingGetPredictions;

  const handleChangeRound = (value?: number) => {
    setRoundIdState(value);
  };

  //maneja los cambios de ronda y predicciones de la ronda
  useEffect(() => {
    //Si existe algun round id y no hay data de la ronda todavia hacer el get
    if (!round && selectedRoundId) {
      refetchRound();
    }

    //si existe round data pero la ronda id seleccionada es distinta volvera hacer el get
    if (selectedRoundId && selectedRoundId !== round?.id) {
      refetchRound();
    }

    //Si existe round id y no busco predicciones o las predicciones corresponden a otro round id volvera hacer el get
    if (
      round?.id &&
      (!responsePredictions || responsePredictions?.roundId !== round?.id) &&
      secretSavePredictions &&
      email
    ) {
      fetchPredictions({
        email,
        roundId: round.id,
        secretSavePredictions,
        searchMutate,
      });
    }
  }, [
    selectedRoundId,
    round?.id,
    secretSavePredictions,
    email,
    responsePredictions,
  ]);

  //Maneja los cambios de fixture o torneo
  useEffect(() => {
    if (
      selectedTournamentId &&
      (!tournamentData || tournamentData.id !== selectedTournamentId)
    ) {
      refetchGetTournament();
    }
  }, [selectedTournamentId, tournamentData?.id]);

  return (
    <Ticket
      fixtureName={round?.name}
      tournamentName={tournamentData?.name}
      rounds={tournamentData?.rounds}
      onChangeRoundId={handleChangeRound}
      onlyView
      predictions={responsePredictions?.predictions as SimplePrediction[]}
      roundSelected={round}
      isLoading={isLoading}
    />
  );
};

export default TournamentPredictionResult;
